import React from "react";
import {
  SiCsharp,
  SiPhp,
  SiDotnet,
  SiAngular,
  SiCodeigniter,
  SiHtml5,
  SiCss3,
  SiJquery,
  SiBootstrap,
  SiTailwindcss,
  SiReact,
  SiMysql,
  SiMicrosoftsqlserver,
  SiDocker,
  SiFlutter,
  SiMongodb,
  SiJavascript,
  SiNextdotjs
} from "react-icons/si";

const YazilimDeneyimi = () => {
  return (
    <div>
      <div className="text-2xl underline text-center text-red-700 p-3 mt-4">
        Kullandığım Teknolojiler
      </div>
      <div className="flex flex-row flex-wrap gap-4 text-sky-900 p-7 justify-center">
        <SiPhp size={60} title="PHP" />
        <SiCodeigniter size={50} title="CODEIGNITER" />
        <SiCsharp size={50} title="C#" />
        <SiDotnet size={50} title="DOTNET" />
        <SiJavascript size={50} title="JAVASCRIPT" />
        <SiFlutter size={50} title="FLUTTER" />

        <SiAngular size={50} title="ANGULAR" />
        <SiReact size={50} title="REACT JS" />
        <SiNextdotjs size={50} title="NEXTJS" />

        <SiHtml5 size={50} title="HTML5" />
        <SiCss3 size={50} title="CSS3" />
        <SiJquery size={50} title="JQUERY" />
        <SiBootstrap size={50} title="BOOTSTRAP" />
        <SiTailwindcss size={50} title="TAILWIND CSS" />

        <SiMysql size={50} title="MYSQL" />
        <SiMicrosoftsqlserver size={50} title="MSSQL" />
        <SiMongodb size={50} title="MONGO DB" />
        <SiDocker size={50} title="DOCKER" />
      </div>
    </div>
  );
};

export default YazilimDeneyimi;
