import React from "react";

const ProfileImage = () => {
  return (
    <div className="text-center">
      <div className="flex justify-center p-3">
        <img
          src="mhmt.jpg"
          alt="mhmt"
          className="rounded-full w-40 transition delay-100 ease-in-out hover:scale-110 border-2"
        />
      </div>
      <div>
        <p className="text-2xl">Mehmet Arıkan</p>
      </div>
      <div className="text-sm text-slate-400">Full-Stack Web Developer</div>
    </div>
  );
};

export default ProfileImage;
