import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";

const SocialIcon = () => {
  return (
    <div className="flex flex-row m-3 justify-center text-cyan-800">
      <div className="p-1">
        <a href="https://linkedin.com">
          <FaLinkedin size={30} />
        </a>
      </div>
      <div className="p-1">
        <a href="https://github.com">
          <FaGithub size={30} />
        </a>
      </div>
    </div>
  );
};

export default SocialIcon;
