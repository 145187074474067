import React from "react";
import { Link, Outlet } from "react-router-dom";
import ProfileImage from "./ProfileImage";
import SocialIcon from "./SocialIcon";

const Layout = () => {
  return (
    <div className="max-w-3xl mx-auto">
      <div className="border-2 border-slate-500/10 rounded-sm m-2">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-1/3">
            <ProfileImage />
            <SocialIcon />
            <div className="">
              <ul>
                <li>
                  <Link to="/" className="p-2 border-2 block hover:bg-cyan-800 hover:text-white">
                    Kullandığım Teknolojiler
                  </Link>
                </li>
                <li>
                  <Link to="/project" className="p-2 border-2 block hover:bg-cyan-800 hover:text-white">
                    Hazırladığım Projeler
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="md:w-2/3">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
